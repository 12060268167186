import {LogLevel} from "@azure/msal-browser"

export const msalConfig = (clientId, tenant) => {
    /**
     * For a full list of configuration parameters:
     * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/configuration.md
     */

    return {
        auth: {
            clientId,
            authority: `https://login.microsoftonline.com/${tenant}`,
            redirectUri: window.location.origin
        },
        cache: {
            cacheLocation: "sessionStorage",
            storeAuthStateInCookie: false // Set this to "true" if you are having issues on IE11 or Edge
        },
        system: {
            loggerOptions: {
                loggerCallback: (level, message, containsPii) => {
                    if (containsPii) {
                        return
                    }
                    switch (level) {
                        case LogLevel.Error:
                            console.error(`Azure-sso Error: ${message}`)
                            return
                        case LogLevel.Info:
                            console.info(`Azure-sso Info: ${message}`)
                            return
                        case LogLevel.Verbose:
                            console.debug(`Azure-sso Verbose: ${message}`)
                            return
                        case LogLevel.Warning:
                            console.warn(`Azure-sso Warning: ${message}`)
                            return
                    }
                }
            }
        }
    }
}
/**
 * Scopes you add here will be prompted for user consent during sign-in.
 * By default, MSAL.js will add OIDC scopes (openid, profile, email) to any login request.
 * For more information about OIDC scopes:
 * https://docs.microsoft.com/en-us/azure/active-directory/develop/v2-permissions-and-consent#openid-connect-scopes
 */
export const loginRequest = {
    scopes: ["openid", "profile", "email", "User.Read"]
}

/**
 * Add here the scopes to request when obtaining an access token for MS Graph API. For more information:
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-browser/docs/resources-and-scopes.md
 */
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
}
