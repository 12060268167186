import * as React from "react"
import * as ReactDOM from "react-dom"
import "./index.css"
import {Model} from "./Model"
import {GateListener} from "./lib/gate/GateListener"
import {createBrowserHistory} from "history"
import {Gate} from "./lib/gate/Gate"
import {Router as ReactRouter} from "react-router"
import {AuthLayer} from "./components/AuthLayer"
import {Bridge} from "./Bridge"
import {getParameterByName, isSSOHashAuth} from "./lib/functions/getParameterByName"
import dotenv from "dotenv"
import {MsalProvider} from "@azure/msal-react"
import {msalConfig} from "./config/azureAuthConfig"
import {PublicClientApplication} from "@azure/msal-browser"

dotenv.config()
const lead_source = getParameterByName("etrk")
if (lead_source && lead_source !== "") {
    localStorage.setItem("lead_source", lead_source)
}

document.domain = document.domain.replace("platform.", "")

const gate = new Gate()
new GateListener(gate)
const history = createBrowserHistory()
const model = new Model(gate, history)

async function main() {
    if (isSSOHashAuth()) return
    await model.auth.refreshToken()
    await model.loadMe()
}

main()

// @ts-ignore
window.model = model

// @ts-ignore
window.bridge = new Bridge(model)
// clientId: 6eb58ac2-753e-4c3c-856a-918e8e031685
// tenant: 736f1683-ea2a-4e24-9c91-a5b593a35c84
const msalInstance = new PublicClientApplication(
    msalConfig(process.env.REACT_APP_MICROSOFT_CLIENT_ID, process.env.REACT_APP_MICROSOFT_TENANT)
)

ReactDOM.render(
    <MsalProvider instance={msalInstance}>
        <ReactRouter history={history}>
            <AuthLayer gate={gate} model={model} auth={model.auth} />
        </ReactRouter>
    </MsalProvider>,
    document.getElementById("root")
)
