import React from "react"
import {useMsal} from "@azure/msal-react"
import {loginRequest} from "../../config/azureAuthConfig"
import {Button} from "pages/sign-in/parts/Button"
import styles from "./SignInSsoButton.module.css"
import {Model} from "Model"

interface SignInSsoButtonProps {
    onSuccess(arg0: {email: string; jwt: string; password?: string}): void
    onStart(): void
    onFinish(): void
    domain: string
    loading?: boolean
    model: Model
}

export const SignInSsoButton = (props: SignInSsoButtonProps) => {
    const {instance} = useMsal()

    const handleLogin = async () => {
        props.onStart()
        try {
            props.model.gate.setLoading(true)
            const ssoResponse: any = await instance.loginPopup(loginRequest)
            console.log("SignInSsoButton logged in with ", ssoResponse)
            const {
                account: {username},
                idToken
            } = ssoResponse
            props.onSuccess({email: username, jwt: idToken})
            props.model.gate.setLoading(false)
        } catch (e) {
            console.error("SignInSsoButton", e)
            props.model.gate.setLoading(false)
        } finally {
            props.onFinish()
            props.model.gate.setLoading(false)
        }
    }
    const {domain} = props

    return (
        <Button
            text={`SIGN IN WITH ${domain} SSO`}
            onClick={handleLogin}
            className={styles.loginButton}
            disabled={props.model.gate.getLoading()}
        />
    )
}
